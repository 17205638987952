import React, { useState, useEffect, useContext, createContext } from 'react';
import { sumBy, isNil } from 'lodash';
import { property, map } from 'lodash/fp';
import Client from 'shopify-buy';

import checkoutModel from '../models/checkout';
import checkoutQuery from '../shopify/graphql/checkoutQuery';

export const CartContext = createContext();

// NOTE: These are public keys so this is OK to push to repo for now,
// but let's make these environment variables later
const domain = 'shop.usmadesupply.com';
const storefrontAccessToken = '58f6bc0a8f08b4141a4b8644fb39a5ff';

const isCheckoutValid = (checkout) => !isNil(checkout) && !checkout.completedAt;

export const CartProvider = ({ children }) => {
  const shopifyClient = Client.buildClient({
    domain,
    storefrontAccessToken
  });
  const [checkoutId, setCheckoutId] = useState(checkoutModel.getCheckout());
  console.log('checkoutId = ', checkoutId);
  const [checkout, setCheckout] = useState();

  useEffect(() => {
    const getOrCreateCheckout = async () => {
      const existingCheckout = checkoutId && (await shopifyClient.checkout.fetch(checkoutId));

      if (!isCheckoutValid(existingCheckout)) {
        const checkout = await shopifyClient.checkout.create();

        setCheckoutId(checkout.id);
        checkoutModel.setCheckout(checkout.id);
        setCheckout(checkout);
      } else {
        setCheckout(existingCheckout);
      }
    };

    getOrCreateCheckout();
  }, [checkoutId]);

  return (
    <CartContext.Provider
      value={{ checkout: checkout ?? {}, setCheckout, checkoutId, shopifyClient }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const { checkout, setCheckout, checkoutId, shopifyClient } = useContext(CartContext);

  console.log('checkout id is ', checkoutId);
  console.log('checkout is ', checkout);

  const addItem = async (variantId, quantity) => {
    const newCheckout = await shopifyClient.checkout.addLineItems(checkoutId, [
      { variantId, quantity }
    ]);
    setCheckout(newCheckout);
  };

  const removeItem = async (lineItemId) => {
    const newCheckout = await shopifyClient.checkout.removeLineItems(checkoutId, [lineItemId]);
    setCheckout(newCheckout);
  };

  const quantity = checkout?.lineItems ? sumBy(checkout?.lineItems, 'quantity') : null;

  const clear = async () => {
    const allLineItems = map(property('id'), checkout.lineItems);

    const newCheckout = await shopifyClient.checkout.removeLineItems(checkoutId, allLineItems);

    setCheckout(newCheckout);
  };

  return { addItem, removeItem, checkout, quantity, clear };
};

export default useCart;
