import React from 'react';
import { Link, Script } from 'gatsby';
import { FirebaseAppProvider } from 'reactfire';
import { PrismicProvider } from '@prismicio/react';
import FirebaseJunk from './src/firebase';
import { CartProvider } from './src/hooks/useCart';
import { AnnouncementProvider } from './src/hooks/useAnnouncement';
import './src/css/main.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import firebaseConfig from './firebaseConfig';

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
});

// Initialize dataLayer and gtag queue
if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag('js', new Date());
  window.gtag('config', 'AW-434630740');

  window.gtag_report_conversion = function (url) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-434630740/KigiCO-imt4ZENTgn88B',
      value: 1.0,
      currency: 'USD',
      event_callback: function () {
        if (typeof url !== 'undefined') {
          window.location = url;
        }
      }
    });
    return false;
  };
}

export function wrapRootElement({ element }) {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseJunk>
        <CartProvider>
          <AnnouncementProvider>
            <PrismicProvider internalLinkComponent={Link}>{element}</PrismicProvider>
          </AnnouncementProvider>
        </CartProvider>
      </FirebaseJunk>
    </FirebaseAppProvider>
  );
}

export function wrapPageElement({ element }) {
  return (
    <>
      {process.env.NODE_ENV === 'production' && (
        <>
          <Script id="gtm" src="https://www.googletagmanager.com/gtm.js?id=GTM-WRSD2RT" />
          <Script id="gtag" src="https://www.googletagmanager.com/gtag/js?id=AW-434630740" />
        </>
      )}
      {element}
    </>
  );
}

export function onRouteUpdate() {
  if (process.env.NODE_ENV === 'production') {
    setTimeout(() => {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'page_view');
      }
    }, 50);
  }
}
