const isWindowDefined = typeof window !== `undefined`;

export const setCheckout = (value) =>
  isWindowDefined && window.localStorage.setItem('checkoutId', value);

export const getCheckout = () =>
  isWindowDefined ? window.localStorage.getItem('checkoutId') : null;

export const clearCheckout = () => isWindowDefined && window.localStorage.removeItem('checkoutId');

export default { setCheckout, getCheckout, clearCheckout };
