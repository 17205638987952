import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  layerStyles: { content: { maxWidth: '1200px', width: '100%' } },
  textStyles: {
    largeTitle: {
      fontSize: ['28px', null, '36px', '50px'],
      fontWeight: 500,
      lineHeight: ['34px', null, '40px', '59px']
    },
    largeSubtitle: {
      fontSize: '17px',
      lineHeight: '22px'
    },
    bold: {
      fontWeight: 'bold'
    },
    'drawer-header': {
      fontWeight: 500,
      fontSize: '28px',
      lineHeight: '34px',
      letterSpacing: '0.38px',
      fontFamily: 'Rubik',
      color: 'white'
    },
    'drawer-top-level-item': {
      fontWeight: 'bold',
      fontSize: '17px',
      lineHeight: '20px',
      color: 'gray.2'
    }
  },
  fonts: {
    body: 'Roboto'
  },
  colors: {
    navy: '#1F3E58',
    tan: '#F5F0E3',
    yellow: '#FCB848',
    white: '#FFFFFF',
    orange: '#FC704A',
    gray: { 1: '#8E8E93', 2: '#AEAEB2', 4: '#D1D1D6', 6: '#F2F2F7' }
  },
  fontSizes: {
    sm: '16px',
    md: '18px'
  },
  components: {
    Text: {
      variants: {
        'product-tile-label': {
          fontFamily: 'Roboto',
          color: 'navy',
          lineHeight: '20px',
          fontSize: '15px',
          fontWeight: ''
        },
        'product-tile-price': {
          fontFamily: 'Rubik',
          fontWeight: 500,
          lineHeight: '25px',
          fontSize: ['17px', null, '20px'],
          color: 'navy'
        },
        'category-description': {
          fontFamily: 'Roboto',
          lineHeight: '22px',
          color: 'navy',
          mt: '20px'
        },
        'footer-section-title': {
          color: 'white',
          fontFamily: 'Rubik',
          lineHeight: '17px',
          fontSize: '17px',
          fontWeight: 500
        },
        'footer-company-label': {
          fontSize: '13px',
          lineHeight: '18px',
          color: 'gray.4',
          opacity: 0.48,
          mt: ['32px', null, '61px']
        },
        'cart-default': { my: 2, fontSize: '15px', lineHeight: '20px' },
        'cart-prod-title': {
          fontSize: '17px',
          lineHeight: '22px',
          fontFamily: 'Rubik',
          fontWeight: 500
        },
        'cart-item-price': {
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '25px',
          fontFamily: 'Rubik',
          mt: '12px',
          mb: '16px'
        },
        'cart-item-remove': {
          fontSize: '13px',
          color: 'gray.2',
          cursor: 'pointer',
          mt: '16px'
        },
        'resource-item-intro': {
          fontFamily: 'Roboto',
          fontSize: '13px',
          lineHeight: '18px',
          color: 'navy',
          opacity: '50%',
          mt: 3
        }
      }
    },
    Link: {
      variants: { 'footer-section-link': { color: 'white', _hover: { layerStyle: 'bold' } } }
    },
    Accordion: {
      parts: ['container', 'button', 'panel'],
      variants: {
        drawer: {
          container: { border: 'none' },
          button: {
            pl: 0,
            color: 'gray.2',
            width: 'auto',
            fontFamily: 'Roboto',
            transition: 'none',
            _expanded: { color: 'yellow', fontWeight: 'bold' }
          },
          panel: { color: 'white', ml: 4, pl: 0 }
        }
      }
    },
    Stack: { variants: { drawer: { spacing: '24px' } } },
    Breadcrumb: {
      parts: ['link', 'separator'],
      baseStyle: {
        link: {
          color: 'gray.1',
          fontSize: '13px',
          lineHeight: '18px',
          fontWeight: 'normal'
        }
      }
    },
    Heading: {
      variants: {
        'category-title': {
          fontFamily: 'Rubik',
          fontWeight: 500,
          lineHeight: '41px',
          color: 'navy',
          fontSize: '34px',
          mt: '40px',
          mb: '32px'
        },
        'product-title': {
          fontFamily: 'Rubik',
          fontWeight: 500,
          fontSize: '22px',
          lineHeight: '28px',
          color: 'navy'
        },
        'general-content-header': {
          fontFamily: 'Rubik',
          fontWeight: 500,
          fontSize: '34px',
          lineHeight: '41px',
          color: 'navy'
        },
        'product-overview-subheading': {
          fontFamily: 'Rubik',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '25px',
          color: 'navy',
          mb: 3
        },
        'resources-page-heading': {
          fontFamily: 'Rubik',
          lineHeight: '41px',
          fontSize: '34px',
          color: 'navy',
          fontWeight: 500,
          mt: 12,
          mb: 8
        },
        'resource-item': {
          fontFamily: 'Rubik',
          fontWeight: 500,
          fontSize: '17px',
          lineHeight: '22px',
          letterSpacing: '-0.43px',
          color: 'navy'
        }
      }
    },
    Divider: {
      baseStyle: { color: 'gray.6' }
    },
    Popover: {
      parts: ['popper', 'arrow'],
      baseStyle: { popper: { maxW: ['100%', 'sm'] }, arrow: { position: 'relative !important' } }
    },
    Button: {
      variants: {
        pri: ({ colorScheme: c }) => {
          return {
            bg: c,
            color: c === 'navy' || c === 'yellow' ? 'white' : 'navy',
            border: 0,
            borderRadius: 0,
            fontFamily: 'Rubik',
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '22px',
            h: 'auto',
            p: 4
          };
        }
      }
    }
  },
  styles: {
    global: {
      body: { color: 'navy' },
      h1: {
        fontFamily: 'Rubik',
        color: 'navy',
        fontWeight: '500',
        fontSize: '1.5rem'
      },
      h2: {
        fontFamily: 'Rubik',
        color: 'navy'
      },
      h3: {
        fontFamily: 'Rubik',
        color: 'navy',
        fontWeight: '400',
        fontSize: '28px',
        lineHeight: '34px'
      },
      h4: { fontFamily: 'Rubik', fontWeight: 500, fontSize: '22px' },
      p: {
        fontFamily: 'Roboto',
        fontSize: 'md',
        lineHeight: '180%',
        color: 'navy'
      },
      ul: { listStylePos: 'inside' }
    }
  }
});

export default theme;
