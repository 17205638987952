import React, { useState, useEffect, useContext, createContext } from 'react';
import * as Prismic from '@prismicio/client';

import prismicClient from '../prismic';

export const AnnouncementContext = createContext();

export const AnnouncementProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await prismicClient.get({
        filters: [Prismic.filter.at('document.type', 'announcement')]
      });
      setMessage(res?.results?.[0]?.data?.body?.[0]?.text);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (message) setTimeout(() => setIsOpen(true), 1000);
  }, [message]);

  return (
    <AnnouncementContext.Provider value={{ message, isOpen, setIsOpen }}>
      {children}
    </AnnouncementContext.Provider>
  );
};

export const useAnnouncement = () => {
  return useContext(AnnouncementContext);
};

export default useAnnouncement;
