import React from 'react';
import { useFirebaseApp, DatabaseProvider } from 'reactfire';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';

export const FirebaseJunk = ({ children }) => {
  const app = useFirebaseApp();
  const database = getDatabase(app);

  if (process.env.NODE_ENV !== 'production') {
    try {
      connectDatabaseEmulator(database, 'localhost', 9001);
    } catch (e) {
      console.error(e);
    }
  }

  return <DatabaseProvider sdk={database}>{children}</DatabaseProvider>;
};

export default FirebaseJunk;
