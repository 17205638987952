const firebaseConfig = {
  apiKey: 'AIzaSyDmvmPt0xJ407gtYXK8ar0fhIht9VqoNk8',
  authDomain: 'marketplace-app-5c58b.firebaseapp.com',
  databaseURL: 'https://marketplace-app-5c58b.firebaseio.com',
  projectId: 'marketplace-app-5c58b',
  storageBucket: 'marketplace-app-5c58b.appspot.com',
  messagingSenderId: '264822025035',
  appId: '1:264822025035:web:ba67f83a38ed008de596ce',
  measurementId: 'G-77PEXMP68V'
};

export default firebaseConfig;
